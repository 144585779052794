<template>
  <div class="meeting-link" id="meeting-link">
    <div v-if="error_mesage == 'member_text_needed'" class="error_mesage">
      <p>برجاء كتابة نص المهمة</p>
    </div>
    <div v-if="error_mesage == 'member_task_needed'" class="error_mesage">
      <p>برجاء إختيار احد الأعضاء لاداء المهمة</p>
    </div>
    <form class="row">
      <!-- <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span>ربط الاجتماع ب task </span>
                      <span class="optinal_label">(اختياري)</span>
                    </div>
                    <div class="input-search position-relative">
                      <input
                        v-model="task_link"
                        class="form-control"
                        type="search"
                        name="meeting-link"
                        id=""
                        placeholder="الرابط"
                      />
                    </div>
                  </div> -->
      <div class="form-group col-md-12 col-12">
        <div class="title">
          <span>مواضيع الاجتماع</span>
        </div>
        <div class="input-search position-relative">
          <input
            v-model="AddTopics"
            class="form-control"
            type="search"
            name="meeting-link"
            id=""
            placeholder="موضوع الاجتماع"
          />
          <button type="button" @click="btnAddTopics" class="add_input_icon">
            <img src="/media/svg/plus.svg" />
          </button>
        </div>

        <div v-if="allTopicsSelected.length" class="topics_list">
          <ul>
            <li
              v-for="(item, index) in allTopicsSelected"
              :key="item.id"
              class="d-flex"
            >
              <div
                v-if="Edit_Topick && edited_topick == index"
                class="w-100 input-search position-relative"
              >
                <input
                  ref="editedTopic"
                  v-model="EditedTopic"
                  class="form-control"
                  type="search"
                  name="meeting-link"
                  id="editedTopic"
                  placeholder="موضوع الاجتماع"
                />
                <button
                  type="button"
                  @click="EditTopickinPlace(index)"
                  class="add_input_icon"
                >
                  <img src="/media/svg/floppy-disk.svg" />
                </button>
              </div>
              <div v-else class="topic_item">
                <i class="fas fa-bars"></i>
                {{ item }}
                <div class="action_btn">
                  <button
                    type="button"
                    @click="EditTopick(item, index)"
                    class="btn text-center"
                  >
                    <img
                      style="width: 17px"
                      src="/media/svg/edit_metings.svg"
                    />
                  </button>
                  <button
                    type="button"
                    @click="removeTopick(index)"
                    class="btn text-center"
                  >
                    <img style="width: 17px" src="/media/svg/delete.svg" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <AddTask
        :allMembersSelected="allMembersSelected"
        :allGroupsSelected="allGroupsSelected"
        :UsersToSelect="UsersToSelect"
        :GroupsToSelect="GroupsToSelect"
        :step2Tasks="step2Tasks"
        :Write_error_mesage="Write_error_mesage"
        :resetMembers="reset_Members"
        :emptyMembers="empty_Members"
      />

      <div class="form-group col-md-12 col-12 form_footer">
        <div class="d-flex justify-content-end">
          <!-- <button class="tajawal-medium">حفظ كمسودة</button> -->
           <button class="btn_Green btn_main"  type="button"  @click="CreateMeetingStep2()">
                       <span>إنشاء الاجتماع</span>
                        <i class="fas fa-arrow-left" style="margin-right: 20px;"></i>
                      </button>
          <!-- <button
            type="button"
            class="btn_Green btn_main"
            @click="CreateMeetingStep2()"
            aria-label="saveasdraft"
          >
            <span>إنشاء الاجتماع</span>
            <img src="/media/svg/long-arrow-left.svg" alt="long arrow left" />
          </button> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import meetings from "@/http/requests/meetings";
import users from "@/http/requests/users";
import groups from "@/http/requests/groups";
import AddTask from "../components/AddTask";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import ConvertDate from "@/Helper/convert_date";
export default {
  name: "NewMeeting2",
  components: {
    AddTask,

  },
  props: ["Singlemeeting", "UsersToSelect", "GroupsToSelect","emptyMembers","resetMembers","steperContainerChange"],
  data() {
    return {
      error_mesage: "",
      allMembersSelected: [],
      allGroupsSelected: [],
      ListMembersSelected: [],
      ListGroupsSelected: [],
      allTopicsSelected: [],
      AddTopics: "",
      meetingParent: {},

      step2Tasks: [],
      Edit_Topick: false,
      edited_topick: undefined,
    
    };
  },
  created() {},
  methods: {
  empty_Members(){
    this.emptyMembers()
  },
    reset_Members(){
    this.resetMembers()
  },
    Write_error_mesage(msg) {
      this.error_mesage = msg;
    },

    btnAddTopics() {
      if (this.AddTopics) {
        if (this.allTopicsSelected.includes(this.AddTopics)) {
          this.$vs.notify({
            text: "تم إضافة الموضوع بالفعل",
            color: "danger",
          });
        } else {
          this.allTopicsSelected.push(this.AddTopics);
        }
      }
      // this.step1Form.groups = this.allTopicsSelected.map((item) => item.id);
      this.AddTopics = "";
    },
    removeTopick(index) {
      this.allTopicsSelected.splice(index, 1);
    },
    removeRequired(index) {
      this.TaskList.splice(index, 1);
    },
    EditTopick(text, index) {
      this.Edit_Topick = true;
      this.edited_topick = index;
      this.EditedTopic = text;
    },
    EditTopickinPlace(index) {
      this.Edit_Topick = false;
      this.allTopicsSelected[index] = this.EditedTopic;
    },

    CreateMeetingStep2() {
      const step2Form = {
        id: this.Singlemeeting.id,
        topics: this.allTopicsSelected,
        tasks: this.step2Tasks,
        // task_link: this.task_link,
      };
      this.$vs.loading();
      meetings
        .CreateMeetingStep2(step2Form)
        .then(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم إضافة تفاصيل الإجتماع بنجاح",
            color: "success",
          });
          this.steperContainerChange(3)
           this.$router.push(
               `/send_meeting_details/${this.Singlemeeting.slug}`
              );
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
  },
};
</script>

