<template>
  <div class="form-group col-md-12 col-12">
    <div class="title">
      <span>المهام المطلوبة</span>
    </div>

    <div class="d-flex mb-5">
      <div class="input-search tasks_input position-relative w-100">
        <input
          v-model="taskText"
          class="form-control"
          type="search"
          name="meeting-link"
          id=""
          placeholder="المهمة المطلوب تنفيذها"
        />
        <button type="button" @click="btnAddTask" class="add_input_icon">
          <img src="/media/svg/plus.svg" />
        </button>
      </div>
      <div class="col-md-4 to_drop_in">
        <draggable
          class="list-group p-1"
          :list="TaskList"
          :group="{ name: 'people' }"
        >
          <template v-if="TaskList.length">
            <div
              v-for="(element, index) in TaskList"
              :key="element.name"
              class="listItem"
            >
              <div class="user_data_draggable mb-2">
                <img :src="element.image ? element.image : element.avatar" />
                <h6 class="user_name">{{ element.name }}</h6>
                <button
                  type="button"
                  class="btn text-center"
                  @click="removetaskMember(element, index)"
                >
                  <img style="width: 15px" src="/media/svg/delete.svg" />
                </button>
              </div>
            </div>
          </template>
          <div class="selectPlaceholder" v-else>إضافة شخص</div>
        </draggable>
      </div>
    </div>
    <draggable
      class="row mb-2"
      :list="UsersToSelect"
      :group="{ name: 'people' }"
    >
      <div
        class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2"
        v-for="element in UsersToSelect"
        :key="element.name"
      >
        <div class="user_data_draggable">
          <img :src="element.avatar" />
          <h6 class="user_name">{{ element.name }}</h6>
        </div>
      </div>
    </draggable>

    <draggable class="row" :list="GroupsToSelect" :group="{ name: 'people' }">
      <div
        class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2"
        v-for="element in GroupsToSelect"
        :key="element.name"
      >
        <div class="user_data_draggable">
          <img :src="element.image" />
          <h6 class="user_name">{{ element.name }}</h6>
        </div>
      </div>
    </draggable>

    <div class="tasks mb-5" v-if="step2TasksShow.length">
      <ul>
        <li v-for="(task, index) in step2TasksShow" :key="task.id">
          <div class="d-flex position_relative w-100">
            <b-dropdown
              position="is-bottom-left"
              class="dropdownList"
              aria-role="list"
            >
              <template #trigger>
                <a class="navbar-item p-0" role="button">
                  <div
                    v-if="task.user && task.user.length"
                    class="user_data_draggable bg_gray m-0"
                  >
                    <img :src="task.user[0].avatar" />
                    <span class="user_name ml-2">{{ task.user[0].name }}</span>
                    <b-icon icon="menu-down mr-auto"></b-icon>
                  </div>
                  <div v-else class="user_data_draggable bg_gray m-0">
                    <img :src="task.group[0].image" />
                    <h6 class="user_name ml-2">{{ task.group[0].name }}</h6>
                    <b-icon icon="menu-down mr-auto"></b-icon>
                  </div>
                </a>
              </template>
              <b-dropdown-item
                paddingless
                aria-role="listitem"
                v-for="item in task.user"
                :key="item.id"
              >
                <div class="user_data_draggable mb-2">
                  <img :src="item.avatar" />
                  <h6 class="user_name">{{ item.name }}</h6>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                paddingless
                aria-role="listitem"
                v-for="item in task.group"
                :key="item.id"
              >
                <div class="user_data_draggable mb-2">
                  <img :src="item.image" />
                  <h6 class="user_name">{{ item.name }}</h6>
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <h6 class="col-md-9">
              {{ task.required }}
            </h6>
            <div class="list_actions">
              <button
                type="button"
                @click="removeRequired(index)"
                class="btn text-center"
              >
                <img style="width: 17px" src="/media/svg/delete.svg" />
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "AddTask",
  components: {
    draggable,
  },
  props: [
    "allGroupsSelected",
    "step2Tasks",
    "Write_error_mesage",
    "allMembersSelected",
    "UsersToSelect",
    "GroupsToSelect",
    "resetMembers",
    "emptyMembers"
  ],
  data() {
    return {
      taskText: "",
      TaskList: [],
      step2TasksShow: [],
      taskGroups: [],
      taskUsers: [],
    };
  },

  methods: {
    removeRequired(index) {
      this.TaskList.splice(index, 1);
    },
    removetaskMember(element, index) {
      this.TaskList.splice(index, 1);
      if (element.image) {
        this.GroupsToSelect.push(element);
      } else {
        this.UsersToSelect.push(element);
      }
    },
    btnAddTask() {
      this.Write_error_mesage("");
      this.taskUsers = [];
      this.taskGroups = [];
      for (let i = 0; i < this.TaskList.length; i++) {
        if (this.TaskList[i].email) {
          this.taskUsers.push(this.TaskList[i]);
        } else {
          this.taskGroups.push(this.TaskList[i]);
        }
      }
      if ((this.taskUsers.length || this.taskGroups.length) && this.taskText) {
        this.emptyMembers()
        this.step2Tasks.push({
          required: this.taskText,
          user: this.taskUsers.map((item) => item.id),
          group: this.taskGroups.map((item) => item.id),
        });
        this.step2TasksShow.push({
          required: this.taskText,
          user: this.taskUsers,
          group: this.taskGroups,
        });

        this.TaskList = [];
        this.taskText = "";
        this.resetMembers()
        // this.UsersToSelect = this.allMembersSelected;
        // this.GroupsToSelect = this.allGroupsSelected;
      } else {
        if (this.taskText) {
          this.Write_error_mesage("member_task_needed");
          window.scrollTo(0, 0);
        } else {
          this.Write_error_mesage("member_text_needed");

          window.scrollTo(0, 0);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-item {
.user_data_draggable{
  width: 200px;
}
}

.to_drop_in {
  margin-left: 0;
  margin-right: 10px;
}
.user_data_draggable {
  display: flex;
  border: 1px solid #e4e6ef;
  padding: 7px 2px;
  position: relative;
  img {
    border-radius: 50%;
    border: 1px solid #e4e6ef;
    width: 30px;
    height: 30px;
    margin-left: 2px;
  }
  h6 {
    display: block;
    margin-top: auto;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: normal;
    // margin: 0;
  }
  button {
    padding: 0;
    position: absolute;
    left: 5px;
    top: 5px;
    img {
      border: none;
    }
  }
}
</style>

