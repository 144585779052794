<template>
  <!-- ADD USER && EDIT USER -->
  <div class="centerx">
    <vs-popup
      class="holamundo"
      :title="'إضافة مجموعه'"
      :active.sync="popupAddGroup"
      :button-close-hidden="true"
    >
      <div class="add_group">
        <div v-if="error_mesage == 'neme_needed'" class="error_mesage">
          <p>برجاء ادخال اسم المجموعة</p>
        </div>
        <div v-if="error_mesage == 'Department_needed'" class="error_mesage">
          <p>برجاء ادخال إسم الإدارة التابعة </p>
        </div>
       
       <div class="form_body">
        <div class="metting_info justify-content-center d-flex">
          <form class="row">
            <div class="col-md-12 form-group mb-0 text-center">
              <div class="photo text-center">
                <div
                  class="imagePreviewWrapper"
                  :style="{ 'background-image': `url(${previewImage})` }"
                >
                  <label
                    title="رفع صورة المستخدم"
                    class="image_uplode_input_label"
                  >
                   <i class="fas fa-upload"></i>
                    <input
                      class="image_uplode_input"
                      ref="fileInput"
                      type="file"
                      @input="pickFile"
                      size="60"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 d-block">
              <label for="group_name">اسم المجموعة</label>
              <input
                v-model="form.name"
                type="text"
                placeholder="اسم المجموعة"
                id="group_name"
              />
            </div>

            <div class="form-group col-md-6 d-block">
              <label for="group_department">الإدارة التابعة</label>
              <input
                v-model="form.department"
                type="group_department"
                placeholder="الإدارة التابعة"
                id="group_name"
              />
            </div>
          </form>
        </div>
      </div>
        <div  class="popoup_footer justify-content-end d-flex">
            <button
              class="btn_Gray btn_main"
              @click="closePopup()"
            >
              <span>خروج</span>
            </button>
            <button class="btn_Green btn_main" @click="CreateGroup()">
              <span>حفظ وخروج</span>
            </button>
          </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import groups from "../../http/requests/groups";
export default {
  name: "AddGroup",
  props:["popupAddGroup","closePopup"],
  data() {
    return {
      previewImage: null,
      user_photo: "",
      form: {},
    };
  },
  components: {},
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      this.user_photo = input.files[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    CreateGroup() {
      this.error_mesage =''
      if (!this.form.name) {
        this.error_mesage = "neme_needed";
      } else if (!this.form.department) {
        this.error_mesage = "Department_needed";
      }
      else{
           let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("department", this.form.department);
      formData.append("group_image", this.user_photo);
      this.$vs.loading();
      groups
        .Creategroup(formData)
        .then(() => {
          this.form = {};
          this.$vs.loading.close();
          this.getAllGroups()
          this.$vs.notify({
            text: "تم إضافة المجموعة بنجاح",
            color: "success",
          });
        })
       .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
      }
    }
  
   
  },
};
</script>

