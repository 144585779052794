<template>
  <div class="new_meeting" data-app>
    <div class="row page_header_all">
      <div class="col-lg-6 col-12 right_content">
        <div class="page_title">
          <div class="title_icon">
            <img src="/media/svg/plus.svg" />
          </div>
          <h3 class="title">إضافة إجتماع جديد</h3>
        </div>
      </div>

      <div class="col-lg-6 action_buttons mr_small">
        <button class="btn_Green btn_main m-0" @click="CreateMeeting('out')">
          <span> حفظ وخروج </span>
        </button>
      </div>
    </div>

    <div class="form_container mt-6">
      <v-stepper v-model="steperContainer">
        <v-stepper-header>
          <v-stepper-step
            :class="steperContainer === 1 ? 'actine_now' : 'actine_Done'"
            step="✓"
            :complete="steperContainer > 1"
            complete-icon="✓"
          >
            تسجيل بيانات الإجتماع
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :class="steperContainer === 2 ? 'actine_now' : ''"
            step="✓"
            :complete="steperContainer > 2"
          >
            تسجيل تفاصيل الإجتماع
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :class="steperContainer === 3 ? 'actine_now' : ''"
            step="✓"
            :complete="steperContainer > 3"
          >
            إرسال الإجتماع
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <div class="meeting-link" id="meeting-link">
                <div v-if="error_mesage == 'title_needed'" class="error_mesage">
                  <p>برجاء ادخال عنوان الإجتماع</p>
                </div>
                <div v-if="error_mesage == 'Users_needed'" class="error_mesage">
                  <p>برجاء اختيار احد المستخدمين او المجموعات للإستمرار</p>
                </div>
                <div
                  v-if="error_mesage == 'coordinator_id_needed'"
                  class="error_mesage"
                >
                  <p>برجاء اختيار منسق الإجتماع</p>
                </div>

                <form class="row">
                  <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span>عنوان الاجتماع</span>
                    </div>
                    <div class="input-search position-relative">
                      <input
                        class="form-control"
                        type="search"
                        name="meeting-link"
                        v-model="step1Form.title"
                        id="name"
                        placeholder="اجتماع لتحسين الموقع الحالي"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span> ربط الاجتماع بمبادرة سابقة</span>
                      <span class="optional_label">(اختياري)</span>
                    </div>

                    <div class="input-search position-relative">
                      <v-select
                        v-model="step1Form.parent_id"
                        @input="getMeetingInfo"
                        :options="meetingsList"
                        placeholder="بحث المبادرات السابقة"
                        label="title"
                        @search="getMeetings"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span>طبيعة الاجتماع</span>
                    </div>

                    <v-radio-group
                      v-model="step1Form.nature_of_meeting"
                      mandatory
                      class="row"
                    >
                      <div class="width_200px">
                        <v-radio
                          label="إجتماع داخلي"
                          value="internal_meeting"
                        ></v-radio>
                      </div>
                      <div class="width_200px">
                        <v-radio
                          label="إجتماع مع عميل"
                          value="client_meetning"
                        ></v-radio>
                      </div>
                      <div class="width_200px"></div>
                    </v-radio-group>
                  </div>
              <!-- <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span>درجة الاهمية</span>
                    </div>
                    <v-radio-group
                      v-model="step1Form.priority_level"
                      mandatory
                      class="row"
                    >
                      <div class="width_200px">
                        <v-radio
                          id="less"
                          label="أقل اهمية"
                          value="less_serious"
                        ></v-radio>
                      </div>
                      <div class="width_200px">
                        <v-radio
                          id="more"
                          label="مهم"
                          value="serious"
                        ></v-radio>
                      </div>
                      <div class="width_200px">
                        <v-radio
                          id="extremly"
                          label="شديد الأهمية"
                          value="very_serious"
                        ></v-radio>
                      </div>
                    </v-radio-group>
                  </div> -->
                   <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span>نوع الاجتماع</span>
                    </div>
                    <v-radio-group
                      v-model="step1Form.meeting_type"
                      mandatory
                      class="row"
                    >
                      <div class="width_200px">
                        <v-radio
                          id="meeting_type"
                          label="خاص"
                          value="private"
                        ></v-radio>
                      </div>
                      <div class="width_400px">
                        <v-radio
                          id="more"
                          label="عام (يمكن دخول أخرين خلال الاجتماع)"
                          value="public"
                        ></v-radio>
                      </div>
                      
                    </v-radio-group>
                  </div> 
                  <div class="form-group col-md-12 col-12 m-0">
                    <div class="title">
                      <span>إضافة الاعضاء</span>
                    </div>
                    <p class="p_find_members">
                      إذا لم تجد الشخص او المجموعة يمكنك إضافتهم من هنا:
                      <a type="button" @click="popupAddUser = true"
                        >إضافة عضو جديد</a
                      >
                      /
                      <a type="button" @click="popupAddGroup = true"
                        >إضافة مجموعة</a
                      >
                    </p>
                  </div>
                  <div
                    class="form-group col-md-12 col-12 display_responsive justify-content-between"
                  >
                    <div
                      class="input-search position-relative customSelect width_45 p-0 margin_res"
                    >
                      <div class="position-relative">
                        <multiselect
                          @input="btnAddMember()"
                          v-model="addMembers"
                          @search-change="getAllUser"
                          :taggable="false"
                          track-by="id"
                          placeholder="إسم الشخص"
                          label="name"
                          :options="allMembers"
                          :option-height="150"
                          :show-labels="false"
                          :multiple="false"
                        >
                          <template slot="option" slot-scope="props">
                            <span class="parent">
                              <img :src="props.option.avatar" />
                              <span class=" "> {{ props.option.name }}</span>
                            </span>
                          </template>
                        </multiselect>

                        <button class="search-icon">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="input-search col-md-6 position-relative customSelect width_45 p-0 margin_res"
                    >
                      <div class="position-relative">
                        <multiselect
                          @input="btnAddGroups()"
                          v-model="addGroups"
                          :taggable="false"
                          track-by="id"
                          placeholder="إسم المجموعة"
                          label="name"
                          :options="allGroups"
                          :option-height="150"
                          :show-labels="false"
                          :multiple="false"
                          @search-change="getAllGroups"
                        >
                          <template slot="option" slot-scope="props">
                            <span class="parent">
                              <img :src="props.option.image" />
                              <span class=" "> {{ props.option.name }}</span>
                            </span>
                          </template>
                        </multiselect>

                        <button class="search-icon">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="allMembersSelected.length || allGroupsSelected.length"
                    class="form-group col-md-12 col-12 d-block"
                  >
                    <div class="allMembers">
                      <div
                        class="memberItem"
                        v-for="(item, index) in allMembersSelected"
                        :key="item.id"
                      >
                        <div class="item-avatar">
                          <img :src="item.avatar" />
                        </div>
                        <div class="item-name">
                          {{ item.name }}
                        </div>
                        <div class="item-remove" @click="removeMember(index)">
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                    <div class="allMembers">
                      <div
                        class="memberItem"
                        v-for="(item, index) in allGroupsSelected"
                        :key="item.id"
                      >
                        <div class="item-avatar">
                          <img :src="item.image" />
                        </div>
                        <div class="item-name">
                          {{ item.name }}
                        </div>
                        <b-dropdown
                          v-if="item.users.length"
                          position="is-bottom-left"
                          class="dropdownList m-0"
                          aria-role="list"
                        >
                          <template #trigger>
                            <a class="navbar-item p-0" role="button">
                              <div class="item-eye">
                                <i class="fas fa-eye"></i>
                              </div>
                            </a>
                          </template>
                          <b-dropdown-item
                            paddingless
                            aria-role="listitem"
                            v-for="user in item.users"
                            :key="user.id"
                          >
                            <div class="user_data mb-2">
                              <img :src="user.avatar" />
                              <span class="user_name">{{ user.name }}</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown
                          v-else
                          position="is-bottom-left"
                          class="dropdownList m-0"
                          aria-role="list"
                        >
                          <template #trigger>
                            <a class="navbar-item p-0" role="button">
                              <div class="item-eye">
                                <i class="fas fa-eye"></i>
                              </div>
                            </a>
                          </template>
                          <b-dropdown-item paddingless aria-role="listitem">
                            <div class="user_data mb-2">
                              <span class="user_name">لا يوجد اعضاء</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                        <div class="item-remove" @click="removeGroup(index)">
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group col-md-12 col-12 justify-content-between"
                  >
                    <div class="borderd padding_20">
                      <div class="title">
                        <span class=" ">وقت الاجتماع</span>
                      </div>
                      <div class="input-border">
                        <v-radio-group
                          v-model="metting_time"
                          mandatory
                          class="row"
                        >
                          <div class="width_200px">
                            <v-radio
                              id="now"
                              label="الآن"
                              value="now"
                            ></v-radio>
                          </div>
                          <div class="width_200px">
                            <v-radio
                              id="rescadual"
                              label="تحديد موعد"
                              value="rescadual"
                            ></v-radio>
                          </div>
                          <div class="width_200px"></div>
                        </v-radio-group>
                      </div>
                    </div>
                    <div
                      class="meetingTime borderd"
                      v-if="metting_time === 'rescadual'"
                    >
                      <div class="row">
                        <div class="col-md-4 mx-0">
                          <b-field label="تاريخ الاجتماع">
                            <b-form-datepicker
                              label-no-date-selected="لم يتم إختيار يوم"
                              :no-flip="true"
                              v-model="meetingDate"
                              :min="new Date()"
                              locale="ar"
                            ></b-form-datepicker>
                          </b-field>
                        </div>
                        <div class="col-md-4 mx-0">
                          <b-field label="بداية الاجتماع">
                            <b-form-timepicker
                              :no-close-button="true"
                              :hide-header="true"
                              :no-flip="true"
                              :hour12="false"
                              label-no-time-selected="لم يتم إختيار وقت"
                              minutes-step="5"
                              v-model="meetingFrom"
                              locale="ar"
                            ></b-form-timepicker>
                          </b-field>
                        </div>
                        <div class="col-md-4 mx-0">
                          <b-field label="نهاية الاجتماع"> </b-field>
                          <b-form-timepicker
                            :no-close-button="true"
                            :hide-header="true"
                            :no-flip="true"
                            :hour12="false"
                            label-no-time-selected="لم يتم إختيار وقت"
                            minutes-step="5"
                            v-model="meetingTo"
                            locale="ar"
                          ></b-form-timepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span class="d-block">منسق الاجتماع</span>
                    </div>
                    <div class="input-search position-relative customSelect">
                      <multiselect
                        @search-change="getAllUser"
                        v-model="step1Form.coordinator_id"
                        :taggable="false"
                        track-by="id"
                        placeholder="إسم الشخص"
                        label="name"
                        :options="allMembers"
                        :option-height="150"
                        :show-labels="false"
                        :multiple="false"
                      >
                        <template slot="option" slot-scope="props">
                          <span class="parent">
                            <img :src="props.option.avatar" />
                            <span class=" "> {{ props.option.name }}</span>
                          </span>
                        </template>
                      </multiselect>
                      <button class="search-icon">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="form-group col-md-12 col-12 form_footer">
                    <div class="d-flex justify-content-end">
                      <!-- <button class="tajawal-medium">حفظ كمسودة</button> -->
                      <!-- <button
                        type="button"
                        class="btn_Green btn_main"
                        @click="CreateMeeting()"
                        aria-label="saveasdraft"
                      >
                        <span>التالي</span>
                        <img
                          src="/media/svg/long-arrow-left.svg"
                          alt="long arrow left"
                        />
                      </button> -->
                      <button  type="button" class="btn_Green btn_main" @click="CreateMeeting()">
                        <span>التالي</span>
                        <i class="fas fa-arrow-left" style="margin-right: 20px;"></i>
                      </button>
                    </div>
                  </div>
                </form>

                <!-- <div class="meeting-required d-flex justify-content-between">
                  <span>
                    <button aria-label="attend">
                      <img src="/media/svg/question-circle.svg" alt="" />
                    </button>
                    <span class="meeting-required-title tajawal-medium"
                      >هل يتطلب الإجتماع حضور إدارة الحسابات</span
                    >
                  </span>
                  <vs-switch color="#1bb04e" v-model="switch2" />
                </div> -->
              </div> --!>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card>
              <NewMeeting2
                v-if="steperContainer == 2"
                :UsersToSelect="UsersToSelect"
                :GroupsToSelect="GroupsToSelect"
                :Singlemeeting="Singlemeeting"
                :resetMembers="resetMembers"
                :emptyMembers="emptyMembers"
                :steperContainerChange="steperContainerChange"
              />
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card>
              <div class="meeting-link" id="meeting-link">
                <div
                  v-if="error_mesage == 'member_text_needed'"
                  class="error_mesage"
                >
                  <p>برجاء كتابة نص المهمة</p>
                </div>
                <div
                  v-if="error_mesage == 'member_task_needed'"
                  class="error_mesage"
                >
                  <p>برجاء إختيار احد الأعضاء لاداء المهمة</p>
                </div>
                <form class="row">
                  <div class="form-group col-md-12 col-12">
                    <div class="title">
                      <span class=" ">ارسال الاجتماع عن طريق</span>
                    </div>
                    <div class="send_meeting">
                      <div class="width_200px">
                        <v-checkbox
                          v-model="send_email"
                          id="email"
                          label="البريد الإلكتروني"
                        ></v-checkbox>
                      </div>

                      <div class="width_200px">
                        <v-checkbox
                          id="SMS"
                          label="SMS"
                          v-model="send_sms"
                        ></v-checkbox>
                      </div>
                      <div class="width_200px">
                        <v-checkbox
                          id="Whatsapp"
                          label="Whatsapp"
                          v-model="send_whatsapp"
                        ></v-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 col-12 form_footer">
                    <div class="d-flex justify-content-end">
                      <!-- <button class="tajawal-medium">حفظ كمسودة</button> -->
                       <button  type="button" class="btn_Green btn_main"  @click="notify_meeting_users()">
                         <span>إرسال الاجتماع</span>
                        <i class="fas fa-arrow-left" style="margin-right: 20px;"></i>
                      </button>
                      <!-- <button
                        type="button"
                        class="btn_Green btn_main"
                        @click="notify_meeting_users()"
                        aria-label="saveasdraft"
                      >
                        <span>إرسال الاجتماع</span>
                        <img
                          src="/media/svg/long-arrow-left.svg"
                          alt="long arrow left"
                        />
                      </button> -->
                    </div>
                  </div>
                </form>
              </div>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <AddMembers
      v-if="popupAddUser"
      :popupAddUser="popupAddUser"
      :closePopup="closePopup"
      :getAllUser="getAllUser"
    />
    <AddGroup
      v-if="popupAddGroup"
      :popupAddGroup="popupAddGroup"
      :closePopup="closePopup"
      :getAllGroups="getAllGroups"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import meetings from "@/http/requests/meetings";
import users from "@/http/requests/users";
import groups from "@/http/requests/groups";
// import VueTimepicker from "vue2-timepicker";
import vSelect from "vue-select";
// CSS
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import ConvertDate from "@/Helper/convert_date";
import AddMembers from "@/components/users/AddMembers";
import AddGroup from "@/components/groups/AddGroup";
import NewMeeting2 from "./NewMeetingStep2";
export default {
  name: "NewMeeting",
  components: {
    "v-select": vSelect,
    Multiselect,
    AddMembers,
    AddGroup,
    NewMeeting2,
  },
  data() {
    return {
      step1Form: {
        nature_of_meeting: "internal_meeting",
        priority_level: "less_serious",
      },
      error_mesage: "",
      addMembers: null,
      addGroups: null,
      allMembersSelected: [],
      UsersToSelect: [],
      GroupsToSelect: [],
      allGroupsSelected: [],
      ListMembersSelected: [],
      ListGroupsSelected: [],
      allMembers: [],
      allGroups: [],

      meetingDate: null,
      meetingFrom: null,
      meetingTo: null,

      time: null,

      form: {},
      metting_time: null,
      Search_metting_value: undefined,
      value: undefined,
      steperContainer: 1,
      meetingsList: [],

      Meetingtopics: [],
      allTopicsSelected: [],
      AddTopics: "",
      meetingParent: {},

      step2Tasks: [],

      task_link: "",
      send_email: false,
      send_whatsapp: false,
      send_sms: false,
      third_party: [],

      popupAddUser: false,
      popupAddGroup: false,
      Edit_Topick: false,
      edited_topick: undefined,

      Singlemeeting: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (this.$route.params.slug) {
      this.getSingleMeeting("detalis");
    } else if (this.$route.params.slug_send) {
      this.getSingleMeeting("send");
    } else {
      this.getMeetings("");
      this.getAllUser("");
      this.getAllGroups("");
    }
  },
  created() {
    if (this.$route.params.slug) {
      this.getSingleMeeting("detalis");
    } else if (this.$route.params.slug_send) {
      this.getSingleMeeting("send");
    } else {
      this.getMeetings("");
      this.getAllUser("");
      this.getAllGroups("");
    }
  },
  methods: {
    steperContainerChange(num) {
      this.steperContainer = num;
    },
    resetMembers() {
      this.getSingleMeeting("detalis");
    },
    emptyMembers() {
      this.UsersToSelect = [];
      this.GroupsToSelect = [];
    },
    closePopup() {
      this.popupAddUser = false;
      this.popupAddGroup = false;
    },

    getMeetings(keyword) {
      this.meetingsList = [];
      meetings.getMeetings(1, keyword).then((res) => {
        this.meetingsList = res.data.data;
      });
    },

    getSingleMeeting(slug,id) {
      this.$vs.loading();
      this.Singlemeeting = {};
      meetings
        .getSingleMeeting(
          this.$route.params.slug
            ? this.$route.params.slug
            : this.$route.params.slug_send?this.$route.params.slug_send
            :id
            
        )
        .then((res) => {
          this.$vs.loading.close();

          this.Singlemeeting = res.data.data;
          this.UsersToSelect = res.data.data.users;
          this.GroupsToSelect = res.data.data.groups;
          if (slug == "detalis") {
            this.steperContainer = 2;
          } else {
            this.steperContainer = 3;
          }
        });
    },
    getAllUser(keyword) {
      this.allMembers = [];
      users.getusers(1, keyword).then((res) => {
        this.allMembers = res.data.data;
      });
    },
    getAllGroups(keyword) {
      this.allGroups = [];
      groups.getgroups(1, keyword).then((res) => {
        this.allGroups = res.data.data;
      });
    },

    CreateMeeting(action) {
      if (this.steperContainer == 1) {
        this.error_mesage = "";
        if (!this.step1Form.title) {
          this.error_mesage = "title_needed";
          window.scrollTo(0, 0);
        } else if (!(this.step1Form.users || this.step1Form.groups)) {
          this.error_mesage = "Users_needed";
          window.scrollTo(0, 0);
        } else if (!this.step1Form.coordinator_id) {
          this.error_mesage = "coordinator_id_needed";
          window.scrollTo(0, 0);
        } else {
          if (this.metting_time == "now") {
            this.step1Form.type = "now";
          } else {
            this.step1Form.type = "rescadual";

            this.step1Form.start_time =
              this.meetingDate + " " + this.meetingFrom;
            this.step1Form.end_time = this.meetingDate + " " + this.meetingTo;
          }
          if (this.step1Form.parent_id) {
            this.step1Form.parent_id = this.step1Form.parent_id.id;
          }
          if (this.step1Form.coordinator_id) {
            this.step1Form.coordinator_id = this.step1Form.coordinator_id.id;
          }
          this.$vs.loading();
          meetings
            .CreateMeeting(this.step1Form)
            .then((res) => {
              this.meetingID = res.data.meeting_id;
              this.$vs.loading.close();
              this.$vs.notify({
                text: "تم إضافة الإجتماع بنجاح",
                color: "success",
              });
              if (action) {
                this.step1Form = {};
                this.$router.push("/meetings_list");
              } else {
                this.step1Form = {};
                this.getSingleMeeting("detalis",res.data.meeting_slug);
                this.steperContainer = 2;
                this.$router.push(
                  `/add_meeting_details/${res.data.meeting_slug}`
                );
              }
            })
            .catch((err) => {
              this.$vs.loading.close();
              if (err.response.data.error) {
                this.$vs.notify({
                  text: err.response.data.error,
                  color: "danger",
                });
              } else {
                this.$vs.notify({
                  text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                  color: "danger",
                });
              }
            });
        }
      } else {
        this.$router.push("/meetings_list");
      }
    },

    // Handle Add Members
    btnAddMember() {
      if (this.addMembers) {
        if (this.allMembersSelected.includes(this.addMembers)) {
          this.$vs.notify({
            text: "تم إضافة العضو بالفعل",
            color: "danger",
          });
        } else {
          this.allMembersSelected.push(this.addMembers);
          this.ListMembersSelected.push(this.addMembers);
        }
      }
      this.UsersToSelect = this.allMembersSelected;
      this.step1Form.users = this.allMembersSelected.map((item) => item.id);
      this.addMembers = [];
    },
    btnAddGroups() {
      if (this.addGroups) {
        if (this.allGroupsSelected.includes(this.addGroups)) {
          this.$vs.notify({
            text: "تم إضافة العضو بالفعل",
            color: "danger",
          });
        } else {
          this.allGroupsSelected.push(this.addGroups);
          this.ListGroupsSelected.push(this.addGroups);
        }
        this.GroupsToSelect = this.allGroupsSelected;
        this.step1Form.groups = this.allGroupsSelected.map((item) => item.id);
        this.addGroups = [];
      }
    },

    // Handle Remove Members
    removeMember(index) {
      this.allMembersSelected.splice(index, 1);
      this.step1Form.users.splice(index, 1);
      this.UsersToSelect = this.allMembersSelected;
    },
    removeGroup(index) {
      this.allGroupsSelected.splice(index, 1);
      this.step1Form.groups.splice(index, 1);
      this.GroupsToSelect = this.allGroupsSelected;
    },

    getMeetingInfo() {
      this.$vs.loading();
      this.meetingParent = [];
      meetings.getSingleMeeting(this.step1Form.parent_id.slug).then((res) => {
        this.$vs.loading.close();
        this.meetingParent = res.data.data;
        this.step1Form.nature_of_meeting = res.data.data.nature_of_meeting;

        this.step1Form.priority_level = res.data.data.priority_level;
        this.step1Form.users = res.data.data.users.map((user) => user.id);
        this.step1Form.groups = res.data.data.groups.map((group) => group.id);
         this.step1Form.coordinator_id = res.data.data.coordinator;
        this.allMembersSelected = res.data.data.users;
        this.UsersToSelect = res.data.data.users;
        this.allGroupsSelected = res.data.data.groups;
        this.GroupsToSelect = res.data.data.groups;
      });
    },
    notify_meeting_users() {
      this.$vs.loading();
      const step3Form = {
        meeting_id: this.Singlemeeting.id,
        email_option: this.send_email ? 1 : 0,
        whatsapp_option: this.send_whatsapp ? 1 : 0,
        sms_option: this.send_sms ? 1 : 0,
      };
      meetings
        .notify_meeting_users(step3Form)
        .then(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم إرسال الإجتماع بنجاح",
            color: "success",
          });
          this.$router.push("/meetings_list");
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
  },
};
</script>

